import './App.css';
import React, { useState, useMemo, useEffect } from 'react';
import { Tabs, Tab, Typography, Button } from '@material-ui/core';
import { LoadScript } from '@react-google-maps/api';
import { GOOGLE_MAPS_API_KEY_PROD } from './config';
import Map from './components/Map';
import CourtAvailabilityView from './components/CourtAvailabilityView';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useLocation } from 'react-router-dom';
import logo from './logo_transparent.png';
import { getAnalytics, logEvent } from "firebase/analytics";

const isDevelopment = process.env.NODE_ENV === 'development';

let GOOGLE_MAPS_API_KEY;

const firebaseConfig = {
  apiKey: "AIzaSyCCdRGb-XlG4liTNInN0waR1DG_C2JRp5M",
  authDomain: "courtclickapp.firebaseapp.com",
  projectId: "courtclickapp",
  storageBucket: "courtclickapp.appspot.com",
  messagingSenderId: "228947922443",
  appId: "1:228947922443:web:12c91bc9a0241dd576010e",
  measurementId: "G-47J7EVHE3P"
};

let app;

if (!firebase.apps.length) {
  app = firebase.initializeApp(firebaseConfig);
} else {
  app = firebase.app();
}
let analytics = getAnalytics(app);

if (isDevelopment) {
  const { developmentConfig } = require('./config.dev');
  GOOGLE_MAPS_API_KEY = developmentConfig.GOOGLE_MAPS_API_KEY;
} else {
  GOOGLE_MAPS_API_KEY = GOOGLE_MAPS_API_KEY_PROD;
  analytics = getAnalytics(app);
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};


const App = () => {
  const query = useQuery();
  const [selectedTab, setSelectedTab] = useState(query.get('tab') || 'courtAvailability');
  const [searchField, setSearchField] = useState(query.get('search') || '');
  const [lastRefreshTime, setLastRefreshTime] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [isFrench, setIsFrench] = useState(true); // Toggle state for language swap

  const handleResize = () => {
    const isMobile = window.innerWidth < 600;
    setIsMobile(isMobile);
  };

  const handleLanguageToggle = () => {
    logEvent(analytics, 'language_change', {
      isFrench: !isFrench,
    })
    setIsFrench(!isFrench);
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top when language is toggled
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const db = firebase.firestore();
    db.collection('lastRefreshTime')
      .doc('time')
      .get()
      .then((doc) => {
        if (doc.exists) {
          setLastRefreshTime(doc.data().time);
        } else {
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }, []);

  useEffect(() => {
    console.log(searchField);
  }, [searchField]);

  useEffect(() => {
    console.log(selectedTab);
  }, [selectedTab]);

  const handleTabChange = (event, newTab) => {
    logEvent(analytics, 'tab_change', {
      tab: newTab,
    })
    setSelectedTab(newTab);
  };

  const handleSearchFieldChange = (event) => {
    logEvent(analytics, 'search_change', {
      searchField: event.target.value,
    })
    setSearchField(event.target.value);
  };

  const renderMap = useMemo(() => {
    return selectedTab === 'map' ? <Map isFrench={isFrench} analytics={analytics} /> : null;
  }, [selectedTab, isFrench]); // Add isFrench as a dependency

  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
      <div className="main">
        <div style={{ position: 'absolute', top: -26, left: -9, margin: "0px" }}>
          <img src={logo} alt="Logo" style={{ width: '100px', height: '100px' }} />
        </div>
        <div className={isMobile ? 'last-refresh-time-mobile' : 'last-refresh-time'}>
          <Typography variant="subtitle2" style={{ fontSize: isMobile ? '10px' : '12px', lineHeight: isMobile ? '1.1' : "inherit"  }}>
            {isFrench ? (isMobile ? "Dernière act.":"Dernière actualisation") : "Last refresh"}: <br />
            {isMobile ? lastRefreshTime.slice(0, 10) : lastRefreshTime.slice(0, 16)}
            <br />
            {isMobile ? lastRefreshTime.slice(10, 16):""}
          </Typography>
        </div>
        <Tabs style={{ height: "5vh" }} value={selectedTab} onChange={handleTabChange} centered>
          <Tab label={isFrench ? "Recherche" : "Court Search"} value="courtAvailability" />
          <Tab label={isFrench ? "Carte" : "Map"} value="map" />
        </Tabs>
        {renderMap}
        {selectedTab === 'courtAvailability' &&
          <CourtAvailabilityView
            searchField={searchField}
            onSearchFieldChange={handleSearchFieldChange}
            isFrench={isFrench} // Pass isFrench as a prop
            analytics={analytics} // Pass analytics as a prop
          />
        }
        <div style={{ textAlign: 'center', marginTop: '0', height: "5vh" }}>
          <p style={{ padding: '1em', margin: '0', fontSize: "medium" }}>
            {isFrench ? (
              <>
                Réalisé avec ❤️ et&nbsp;
                <a href="https://www.buymeacoffee.com/zilongl" onClick={()=>{logEvent(analytics, 'donation_link_click')}} target='_blank' style={{ color: '#64b5f6', textDecoration: "none" }}>
                  ☕(soutenez ma dépendance à la caféine!)
                </a> 
                &nbsp;à Montréal&nbsp;=&gt;
                <span variant="contained" style={{  height: "inherit", color: '#64b5f6', cursor: 'pointer'}} color="primary" onClick={handleLanguageToggle}>
                  &nbsp;({isFrench ? "English please!" : "En Français S.V.P.!"})
                </span>
              </>
            ) : (
              <>
                Made with ❤️ and&nbsp;
                <a href="https://www.buymeacoffee.com/zilongl" onClick={()=>{logEvent(analytics, 'donation_link_click')}} target='_blank' style={{ color: '#64b5f6', textDecoration: "none" }}>
                  ☕(support my caffeine addiction!)
                </a> 
                &nbsp;in Montreal&nbsp;=&gt;
                <span variant="contained" style={{  height: "inherit", color: '#64b5f6', cursor: 'pointer'}} color="primary" onClick={handleLanguageToggle}>
                  &nbsp;({isFrench ? "English please!" : "En Français S.V.P.!"})
                </span>
              </>
            )}
            </p>
        </div>
      </div>
    </LoadScript>
  );
};

export default App;
