// CourtAvailabilityView.js

import React, { useEffect, useState } from 'react';
import { Typography, Input, CircularProgress  } from '@material-ui/core';
import CourtAvailabilityTable from './CourtAvailabilityTable';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { getDistance } from 'geolib';
import { logEvent } from "firebase/analytics";

function accentAndCaseRemover(initialString) {
  if (initialString) {
    const replacementDict = {
      'a': ['â', 'à', 'ä', 'Â', 'À', 'Ä'],
      'e': ['é', 'ê', 'ë', 'è', 'É', 'È', 'Ê', 'Ë'],
      'c': ['ç', 'Ç'],
      'i': ['î', 'ï', 'ì', 'Î', 'Ï', 'Ì'],
      'o': ['ô', 'ö', 'ò', 'Ô', 'Ö', 'Ò'],
      'u': ['û', 'ü', 'ù', 'Û', 'Ü', 'Ù']
    }
    for (var key in replacementDict) {
      replacementDict[key].forEach(
        specialLetter => {
          initialString = initialString.replace(new RegExp(specialLetter, 'g'), key);
        }
      );
    }

    initialString = initialString.toLowerCase();
  }
  return initialString
}

const CourtAvailabilityView = ({ searchField, isFrench, analytics }) => {
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState(searchField || '');
    const [isMobile, setIsMobile] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // New state for loading indicator
  
    const handleResize = () => {
      const isMobile = window.innerWidth < 600;
      setIsMobile(isMobile);
    };
  
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const firebaseConfig = {
            // Your Firebase configuration
            };
        
            if (!firebase.apps.length) {
            firebase.initializeApp(firebaseConfig);
            }
        
            const firestore = firebase.firestore();

            // fetch data from firestore collection overview and document loadedSitesTable
            const overviewSnapshot = await firestore.collection('overview').doc('loadedSitesTable').get();
            const snapShotData = overviewSnapshot.data();
            for (const doc in snapShotData){
                //console.log(doc)
                //console.log(overviewSnapshot.data()[doc])
                const court = snapShotData[doc]
                court['id'] = doc
                court['distanceInKm'] = null
                //console.log(court)
                //console.log(court.sitePosition)
                
                calculateDistance(court.sitePosition, (distanceInKm) => {
                    //console.log(court)
                    court.distanceInKm = distanceInKm;
                });
            }

            const fetchedData = Object.values(snapShotData);
            setData(fetchedData);


            /*
            const unsubscribe = firestore.collection('loadedSitesTable').onSnapshot((snapshot) => {
            const fetchedData = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
                distanceInKm: null,
            }));
        
            // Fetch distance for each court
            fetchedData.forEach((court) => {
                calculateDistance(court.sitePosition, (distanceInKm) => {
                //console.log(court)
                court.distanceInKm = distanceInKm;
                setData(fetchedData);
                });
            });
            });*/
        };

        fetchData();
        handleResize();
        window.addEventListener('resize', handleResize);
      
        return () => {
          //unsubscribe();
          window.removeEventListener('resize', handleResize);
        };
      }, []);

      useEffect(() => {
        if (searchQuery.length >= 3 || (data!==null && data.length > 0 && data[0].distanceInKm !== null)) {
            setIsLoading(true); // Loading indicator
        }
        const timer = setTimeout(() => {
          const filteredData = data.filter((item) => {
            if (searchQuery.length >= 3) {
              let cleanSiteName = accentAndCaseRemover(item.siteName.trim().replace(/ +/g, "").toLowerCase());
              let cleanSearchQuery = accentAndCaseRemover(searchQuery.trim().replace(/ +/g, "").toLowerCase());
    
              return cleanSiteName.includes(cleanSearchQuery);
            } 
            if (item.distanceInKm !== null) {
              return true;
            }
            return false;
          });

          const sortedData = filteredData.sort((a, b) => a.distanceInKm - b.distanceInKm); // Sort the filteredData array based on distanceInKm
    
          setFilteredData(sortedData.slice(0, 10)); // Only show the 10 closest courts
          setIsLoading(false); // Data is ready, loading complete
        }, 300);
    
        return () => {
          clearTimeout(timer);
        };
      }, [data, searchQuery]);

  const calculateDistance = (sitePosition, callback) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };

          const distanceInMeters = getDistance(currentLocation, sitePosition);
          const distanceInKm = (distanceInMeters / 1000).toFixed(2);

          callback(distanceInKm);
        },
        (error) => {
          console.error("Error getting current location:", error);
          callback(null);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  return (
    <div style={{ verticalAlign: 'top', minHeight: '84vh' }}>
      <Typography
        variant="h4"
        component="h1"
        style={{
          fontSize: isMobile ? '24px' : '36px',
          marginBottom: '0.5em',
          marginTop: '1em',
        }}
      >
        {isFrench ? "Rechercher le nom du terrain" : "Search court name"}
      </Typography>
      <Input
        type="text"
        style={{
          height: isMobile ? '40px' : '50px',
          width: isMobile ? 'auto' : 'auto',
          backgroundColor: 'white',
          marginBottom: '1em',
        }}
        value={searchQuery}
        onChange={(e) => {
            setSearchQuery(e.target.value)
            logEvent(analytics, 'search_change', { search_term: e.target.value });}}
        placeholder={isFrench ? "Ex: La Fontaine" : "Ex: La Fontaine"}
      />
      {isLoading ? ( // Show the loading indicator when data is not ready
        <div style={{ textAlign: 'center' }}>
          <CircularProgress size={isMobile ? 60 : 80} style={{ color: '#64b5f6', marginTop: "1em" }} />
        </div>
      ) :
        filteredData.length === 0 && searchQuery.length >= 6 ? (
          <Typography variant="body1" style={{ textAlign: 'center' }}>
            {isFrench ? "Aucun terrain trouvé." : "No courts found."}
          </Typography>
        ) : (
          filteredData.map((item) => (
            <CourtAvailabilityTable style={{ marginTop: "20px" }} data={item} key={item.id} isFrench={isFrench} analytics={analytics} />
          ))
        )

      }
    </div>
  );
};

export default CourtAvailabilityView;