import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import axios from 'axios';
import { GoogleMap, Marker, LoadScript, InfoWindow } from '@react-google-maps/api';
import { GOOGLE_MAPS_API_KEY_PROD } from '../config';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { logEvent } from 'firebase/analytics';

const isDevelopment = process.env.NODE_ENV === 'development';

let GOOGLE_MAPS_API_KEY;

if (isDevelopment) {
  const { developmentConfig } = require('../config.dev');
  GOOGLE_MAPS_API_KEY = developmentConfig.GOOGLE_MAPS_API_KEY;
} else {
  GOOGLE_MAPS_API_KEY = GOOGLE_MAPS_API_KEY_PROD;
}

const firebaseConfig = {
  apiKey: "AIzaSyCCdRGb-XlG4liTNInN0waR1DG_C2JRp5M",
  authDomain: "courtclickapp.firebaseapp.com",
  projectId: "courtclickapp",
  storageBucket: "courtclickapp.appspot.com",
  messagingSenderId: "228947922443",
  appId: "1:228947922443:web:12c91bc9a0241dd576010e",
  measurementId: "G-47J7EVHE3P"
};

const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  '& > *': {
    backgroundColor: '#ADD8E6',
  },
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}

const db = firebase.firestore();

const userLocationIcon = {
  url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(`
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="8" fill='#5384ED' opacity="1" />
      <circle cx="12" cy="12" r="11" fill='#5384ED' opacity="0.3" stroke="#4285F4" stroke-width="2" />
    </svg>
  `),
  scaledSize: { width: 24, height: 24 },
};

const Map = ({ isFrench, analytics }) => {
  const [markers, setMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [userPosition, setUserPosition] = useState(null);
  const [mapCenter, setMapCenter] = useState({
    lat: 45.5017,
    lng: -73.5673,
  });

  const defaultZoom = 12;
  const [tab, setTab] = useState(0);

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const dayAfterTomorrow = new Date(tomorrow);
  dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 1);

  const options = { month: '2-digit', day: '2-digit' };

  const todayLabel = `${today.toLocaleDateString(undefined, options)}`;
  const tomorrowLabel = `${tomorrow.toLocaleDateString(undefined, options)}`;
  const dayAfterTomorrowLabel = `${dayAfterTomorrow.toLocaleDateString(undefined, options)}`;

  const formatAvailability = (stat) => {
    const startDate = new Date(stat.startDateTime);
    const formattedStart = `${startDate.getHours()}:${startDate.getMinutes() < 10 ? '0' : ''}${startDate.getMinutes()}`;
    return (
      <Grid item xs={6} sm={3}>
        <Button
          onClick={() => {
            window.open(stat.linkSearch, "_blank")
            logEvent(analytics, 'click_availability_link', { link: stat.linkSearch, startDate: startDate.toISOString(), startTime: formattedStart });
          }}
          variant="contained"
          style={{ backgroundColor: '#64b5f6', color: 'white', minWidth:"95px", whiteSpace: 'nowrap' }}
        >
          {formattedStart} ({stat.count})
        </Button>
      </Grid>
    );
  };

  const isSameDay = (date1, date2) => {
    return date1.getDate() === date2.getDate() &&
           date1.getMonth() === date2.getMonth() &&
           date1.getFullYear() === date2.getFullYear();
  };

  const isDayAfterTomorrow = (date) => {
    const dayAfterTomorrow = new Date();
    dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
    return isSameDay(date, dayAfterTomorrow);
  };

  useEffect(() => {
    /*if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserPosition({ lat: latitude, lng: longitude });
          setMapCenter({ lat: latitude, lng: longitude });
          logEvent(analytics, 'get_user_position', { status: 'success', lat: latitude, lng: longitude });
        },
        (error) => {
          console.error('Error getting user position:', error);
          logEvent(analytics, 'get_user_position', { status: 'error', message: error.message });
        }
      );
    } else {
      logEvent(analytics, 'get_user_position', { status: 'error', message: 'Geolocation is not supported by this browser.' });
      console.error('Geolocation is not supported by this browser.');
    }*/
  }, []);

  useEffect(() => {
    const getMarkers = async () => {
      try {
        // get overview document from loadedSites collection
        
        //const snapshot = await db.collection('loadedSites').get();
        const overviewSnapshot = await db.collection('overview').doc('loadedSites').get();
        //console.log(overviewSnapshot.data());

        const newMarkers = [];
        //iterate over the dictionnary
        for (const doc in overviewSnapshot.data()){
          const data = overviewSnapshot.data()[doc];
          //console.log(data);

          let lat, lng;

          if (data.sitePosition) {
            lat = data.sitePosition.lat;
            lng = data.sitePosition.lng;
          } else {
            /*const response = await axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                data.siteAddress
              )}&key=${GOOGLE_MAPS_API_KEY}`
            );
            lat = response.data.results[0].geometry.location.lat;
            lng = response.data.results[0].geometry.location.lng;

            await doc.ref.update({
              sitePosition: { lat, lng }
            });*/
          }

          newMarkers.push({
            id: doc.id,
            sitePosition: { lat, lng },
            label: data.siteName,
            siteAddress: data.siteAddress,
            sitePhone: data.sitePhone,
            stats: data.stats,
          });
        }

        setMarkers(newMarkers);
      } catch (err) {
        console.error("Error while fetching markers: ", err);
      }
    };

    getMarkers();
  }, []);

  return (
    <GoogleMap
      mapContainerStyle={{ height: '87.5vh', width: '100%' }}
      center={mapCenter}
      zoom={defaultZoom}
      options={{ clickableIcons: false }}
      onLoad={(map) => {
        map.addListener('dragend', () => {
          console.log('Map dragged');
        });
      }}
    >
      {userPosition && (
        <Marker
          position={userPosition}
          icon={userLocationIcon}
        />
      )}
      {markers.map((marker) => (
        <Marker
          key={marker.id}
          position={marker.sitePosition}
          onClick={() => {
            setSelectedMarker(marker);
            setMapCenter(marker.sitePosition);
            logEvent(analytics, 'click_marker', { siteName: marker.label });
          }}
        />
      ))}

      {selectedMarker && (
        <InfoWindow
          position={{
            lat: selectedMarker.sitePosition.lat + 0.010,
            lng: selectedMarker.sitePosition.lng,
          }}
          onCloseClick={() => {
            setSelectedMarker(null);
          }}
        >
          <div style={{ color: 'black' }}>
            <h2>{selectedMarker.label}</h2>
            <p>{selectedMarker.siteAddress}</p>
            <p>{selectedMarker.sitePhone}</p>
            <div>
              <Typography style={{ marginTop: "2em" }} variant="h5" component="h2" align="center">
                {isFrench ? "Disponibilités" : "Availabilities"}
              </Typography>
              <Box style={{ marginBottom: "1em" }} display="flex" justifyContent="center">
                <Tabs indicatorColor='primary' centered value={tab} onChange={(event, newValue) => {
                  setTab(newValue)
                  logEvent(analytics, 'change_tab_map_date', { tab: newValue });
                }}>
                  <Tab label={todayLabel} />
                  <Tab label={tomorrowLabel} />
                  <Tab label={dayAfterTomorrowLabel} />
                </Tabs>
              </Box>
              {(() => {
                const filteredStats = selectedMarker.stats && selectedMarker.stats.filter(stat => {
                  const date = new Date(stat.startDateTime);
                  if (tab === 0) return isSameDay(date, today);
                  if (tab === 1) return isSameDay(date, tomorrow);
                  if (tab === 2) return isDayAfterTomorrow(date);
                });
                return filteredStats && filteredStats.length > 0 ? (
                  <Grid container spacing={2} style={{ maxWidth: "100%", maxHeight: "100%", margin: "0px", overflowX: "hidden" }}>
                    {filteredStats.map((stat, i) => formatAvailability(stat))}
                  </Grid>
                ) : (
                  <Typography variant="h6" align="center" style={{ margin: "2em" }}>{isFrench ? "Aucune disponibilité trouvée pour ce jour." : "No availabilities found for this day."}</Typography>
                );
              })()}
            </div>
          </div>
        </InfoWindow>
      )}

    </GoogleMap>
  );
};

export default Map;
