import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Link, Tabs, Tab, Collapse, IconButton } from '@material-ui/core';
import { getDistance } from 'geolib';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@material-ui/icons';
import { logEvent } from 'firebase/analytics';

const times = ["09:00", "10:00","11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00"];

const CourtAvailabilityTable = ({ data, isFrench, analytics }) => {
  const [selectedDate, setSelectedDate] = useState(data.tableStats ? Object.keys(data.tableStats[0].availability).sort()[0] : []);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoveredCell, setHoveredCell] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [distance, setDistance] = useState(null);
  const [expanded, setExpanded] = useState(false); // Track the expand/collapse state
  const [loading, setLoading] = useState(true); // Loading state

  const handleResize = () => {
    const isMobile = window.innerWidth < 600;
    setIsMobile(isMobile);
  };

  useEffect(() => {
    //if (data.siteName == "Parc Médéric-Martin"){
    //  console.log(data)
    //}
    const timer = setTimeout(() => {
      handleResize();
      setLoading(false);
    }, 50);

    window.addEventListener('resize', handleResize);
    return () => {
      clearTimeout(timer);
      
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    calculateDistance();
  }, []);

  const calculateDistance = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const currentLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };

          const sitePosition = data.sitePosition;

          const distanceInMeters = getDistance(currentLocation, sitePosition);
          const distanceInKm = (distanceInMeters / 1000).toFixed(2);

          setDistance(distanceInKm);
        },
        error => {
          console.error("Error getting current location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const handleTabChange = (event, newDate) => {
    logEvent(analytics, 'table_reservation_date_change', {
      date: newDate,});
    setSelectedDate(newDate);
  };

  const getLink = (facility, time, date) => {
    const { availability } = facility;
    const slots = availability[date];
    const slot = slots && slots.find(s => s.startTime === time);

    if (slot) {
      return (
        <Link target="_blank" rel="noopener noreferrer" href={slot.link} 
          onClick={()=>{
            logEvent(analytics, 'table_reservation_click', {
              facility: facility.facilityName,
              time: time,
              date: date,
              link: slot.link});
          }} style={{ fontWeight: 'bold', color: 'green' }}>
          {isFrench ? (isMobile ? "Rés." : "Réserver") : "Book"}
        </Link>
      );
    } else {
      return (
        <Typography variant="body2" color="textSecondary">
          {isMobile ? "N/A" : isFrench ? "Non disponible" : "Not available"}
        </Typography>
      );
    }
  };

  const onCellMouseOver = (time, facilityId) => {
    setHoveredRow(time);
    setHoveredCell(facilityId);
  };

  const extractNumber = (name) => {
    const matches = name.match(/\d+/);
    return matches ? parseInt(matches[0]) : 0;
  };

  // add a function to extract tennis or pickleball from the name or any similar name. Return T if tennis, P if pickleball, TC if both are in the name, and empty string if neither
  const extractSport = (name) => {
    name = name.toLowerCase();
  
    if (name.includes("tennis") && name.includes("pickleball")) {
      return "TC"; // Both tennis and pickleball are in the name
    } else if (name.includes("tennis")) {
      return "T"; // Tennis is in the name
    } else if (name.includes("pickleball")) {
      return "P"; // Pickleball is in the name
    } else {
      return ""; // Neither tennis nor pickleball is in the name
    }
  };

  const sortedTableStats = data.tableStats ? [...data.tableStats].sort((a, b) => {
    const nameA = a.facilityName.toUpperCase();
    const nameB = b.facilityName.toUpperCase();

    const numberA = extractNumber(nameA);
    const numberB = extractNumber(nameB);

    if (numberA !== numberB) {
      return numberA - numberB;
    }

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  }) : [];

  // i want the sorted Dates to be in order of the dates, but using all of tableStats entries since some will have dates that others don't
  const availabilityDates = []; 
  // extract keys from data.tableStats and add them to availabilityDates if they aren't already there
  sortedTableStats.forEach((facility) => {
    Object.keys(facility.availability).forEach((date) => {
      if (!availabilityDates.includes(date)) {
        availabilityDates.push(date);
      }
    });
  });

  const sortedDates = availabilityDates.sort();

  const toggleExpanded = () => {
    logEvent(analytics, 'table_reservation_expand', {
      facility: data.siteName,
      expanded: !expanded});
    setExpanded(!expanded);
  };

  return (
    <div style={{ marginTop: "1em", marginBottom: "2em" }}>
      <span style={{ cursor: 'pointer' }} onClick={toggleExpanded}>
        <Typography variant={isMobile ? "h6" : "h4"} component="h2" align="center">
          {loading ? "" : data.siteName} <small>{loading ? "" : data.distanceInKm && `~${data.distanceInKm} km`}</small>
          {loading ? "" : expanded ? <ExpandLessIcon style={{ marginLeft: "1em", color: '#fff' }} /> : <ExpandMoreIcon style={{ marginLeft: "1em", color: '#fff' }} />}
        </Typography>
      </span>
      <Collapse in={expanded}>
        <div>
          <Typography variant={isMobile ? "body2" : "body1"}>{loading ? "" : data.siteAddress} (<Link target="_blank" onClick={(event)=> event.stopPropagation()} style={{ color: '#64b5f6' }} href={loading ? "" : data.siteGoogleMapsLink}>{isFrench ? "Carte" : "Map"}</Link>)</Typography>
          <Typography variant={isMobile ? "body2" : "body1"}>{loading ? "" : data.sitePhone}</Typography>

          {!loading && (
            <Tabs
              value={selectedDate}
              onChange={handleTabChange}
              centered
              variant="fullWidth"
              style={{ marginTop: '20px', marginBottom: '20px' }}
            >
              {sortedDates.map(date => (
                <Tab key={date} label={date} value={date} style={{ minWidth: '0', fontSize: isMobile ? '0.8em' : '1.2em' }} />
              ))}
            </Tabs>
          )}

          {!loading && (
            <TableContainer style={{maxHeight:"90vh"}} component={Paper}>
              <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="stickyColumn">{isFrench ? "Heure" : "Time"}</TableCell>
                  {sortedTableStats !== [] ? sortedTableStats.map(t => (
                    <TableCell style={{ backgroundColor: '#eee' }} key={t.facilityId} className="stickyHeader">
                      {isMobile ? extractSport(t.facilityName)+"#" + extractNumber(t.facilityName) : t.facilityName}
                    </TableCell>
                  )) : <TableCell>Test</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {times.map(time => (
                  <TableRow
                    key={time}
                    onMouseOver={() => onCellMouseOver(time, null)}
                    onMouseOut={() => onCellMouseOver(null, null)}
                    style={{ backgroundColor: hoveredRow === time ? '#eee' : 'transparent' }}
                  >
                    <TableCell style={{ backgroundColor: '#eee' }} className="stickyColumn">{time}</TableCell>
                    {sortedTableStats !== [] ? sortedTableStats.map(facility => {
                      const link = getLink(facility, time, selectedDate);
                      const isAvailable = link.props.href;

                      return (
                        <TableCell
                          key={facility.facilityId}
                          onClick={(event) => {
                            if (isAvailable) {
                              event.stopPropagation();
                              // window.open(link.props.href, '_blank');
                            }
                          }}
                          onMouseOver={() => onCellMouseOver(time, facility.facilityId)}
                          style={{
                            backgroundColor: hoveredRow === time && hoveredCell === facility.facilityId ? '#ddd' : 'transparent',
                            cursor: isAvailable ? 'pointer' : 'default'
                          }}
                        >
                          {link}
                        </TableCell>
                      );
                    }) :
                      <TableCell>Test</TableCell>}
                  </TableRow>
                ))}
              </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default CourtAvailabilityTable;
